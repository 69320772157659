export default function Post(post) {

  if(typeof post === 'object'){
    for (const key in post) {
      if (Object.hasOwnProperty.call(post, key)) {
        const postVal = post[key];
        this[key] = postVal;
      }
    }
  }


  return {
    title : {required: true, label : 'Título'}, 
    content : {required: true}, 
    phone : {required: false}, 
    price: { type : 'number'}, 
    zipcode : {required : false, type : 'text', label : 'Código Postal'}, 
    isNear : (comparedZipCode, userId ) =>  comparedZipCode !== undefined && this.zipcode !== undefined
                                            && this.zipcode === comparedZipCode && userId !== this.userid
  }
}