import { Box, CircularProgress, IconButton, Skeleton, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  limit,
} from "firebase/firestore";
import { useFirestore } from "reactfire";
import NewPost from "./post/NewPost";
import { useUser, StorageProvider } from "reactfire";
import PostDetail from "./post/PostDetail";
import { getStorage } from "firebase/storage";
import AppList from "./list/AppList";
import Instructions from "./info/Instructions";
import Header from "./header/Header";
import { redirect, useLocation, useNavigate } from "react-router-dom";
import { LocalStorageService } from "../services/LocalStorageService";
import { Refresh } from "@mui/icons-material";
import Post from "../model/post";

function Home(props) {
  const cache = new LocalStorageService();
  const user = useUser();
  const [posts, setPosts] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);


  const navigate = useNavigate();
  const firestore = useFirestore();


  useEffect(() => {
    async function doQueries() {
      let q = null;

      let cachedPosts = cache.getAllPosts();

      if (!cachedPosts.length) {
        q = query(
          collection(firestore, "post"),
          where("status", "==", "approved"),
          where(
            "createddate",
            ">",
            new Date().getTime() - 1000 * 60 * 60 * 24 * 60
          ),
          orderBy("createddate", "desc"),
          limit(50)
        );

        let querySnapshot = await getDocs(q)
        let zip = cache.getZipCode();
        querySnapshot.forEach((e) => {
          const post = e.data();
          post.id = e.id;
          post.isNear = (new Post(post)).isNear(zip, user.data ? user.data.uid : '') ;
          cachedPosts.push(post);
        });
        const draftPosts = cache.getDraftPosts(cachedPosts);
        cachedPosts.push(...draftPosts);
        cache.saveAllPosts(cachedPosts);
      }

      cachedPosts = cachedPosts.sort((a, b) => a.isNear  ?  -1 : 0);
      if(posts.length !== cachedPosts.length )
        setPosts(cachedPosts);
    }
    doQueries();
  }, [isReady, user.data, posts]);

  const updateList = function (newPost) {
    if (newPost) {
      let cachedPosts = cache.addPost(newPost);
      setPosts(cachedPosts);
    }
    setIsReady(false);
  };

  const clearSelectedPost = (update) => {
    if (update) {
      setIsReady(false);
    }
    setSelectedPost(null);
  };

  const openPostDetail = (post) => {
    navigate("/post/" + post.id);
    //setSelectedPost(post);
  };

  const [forced, setForced] = useState(false);
  function forceRefresh(params) {
    cache.clearPosts();
    setPosts([]);
    setForced(true);
  }

  return (
    <Box sx={{ m: 'auto', width : {xs: '100%', md : '80%'}, marginBottom : 10, position : 'relative' } }>
      <Instructions></Instructions>
      <Typography variant="h6" sx={{ margin: 2, textAlign: "center", color : 'primary.light'}}>
        🛒 Art&iacute;culos recomendados 
        <IconButton onClick={forceRefresh} disabled={forced}>
          <Refresh></Refresh>
        </IconButton>
      </Typography>
      {(!posts || !posts.length) && (
        <Box sx={{ textAlign: "center" }}>
          <CircularProgress color="primary"></CircularProgress>
        </Box>
      )}
      {posts && posts.length > 0 && (
        <AppList
          items={posts}
          onSelectedPost={openPostDetail}
          onFinishedPost={updateList}
        ></AppList>
      )}
    </Box>
  );
}

function triagePosts(posts, categories) {
  let lists = [];

  posts.forEach((element) => {
    if (element.categories && categories[element.categories[0]]) {
      categories[element.categories[0]].posts.push(element);
    }
  });

  for (const key in categories) {
    if (Object.hasOwnProperty.call(categories, key)) {
      const category = categories[key];
      lists.push(category);
    }
  }

  return lists;
}

export default Home;
