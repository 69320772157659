import { Box } from "@mui/system";
import Typography from '@mui/material/Typography'
import { useEffect } from "react";
import { listAll, ref, getDownloadURL } from "firebase/storage";
import { useStorage } from "reactfire";
import { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, Link } from "@mui/material";

function Donations() {
    const bitsoCode = 'bitsogcode.png';
    const storage =  useStorage();
    const [codeUrl, setCodeUrl] = useState('');
    const [codeId, setCodeId] = useState(bitsoCode);
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        const fileRef = ref(storage, "donationcodes/"+codeId);

        (async ()=>{
            try {
                let url = await getDownloadURL(fileRef);
                setCodeUrl(url);
                // const photos = [];
                // if(files.items)
                //     for (let index = 0; index < files.items.length; index++) {
                //         const item = files.items[index];
                //         const url = await getDownloadURL(item);
                //         photos.push(url);
                //     }
                // setCodes(photos);
            } catch (error) {
                console.log(error);
            }

        })();
        // let adsbygoogle = window.adsbygoogle || [];
        // adsbygoogle.push({});
    }, [codeId]);

    function handleOpenDialog(event){
        setCodeId(event.currentTarget.id);
        setOpenDialog(true);
    }

    return ( <Box sx={{marginBottom : 10}}>
        <Typography variant="h6" color="primary.light" sx={{m : 4}} >
            Donativos 💙
        </Typography>
        <Typography variant="body1" color="initial" sx={{m : 4, textAlign : 'justify'}}>
            <p>Hola, espero estes disfrutando la app. </p>
            <p>Si tienes alguna sugerencia o comentario, o deseas reportar un problema. 
                Puedes contactarme al siguiente correo &nbsp;
                <Link color="secondary" href='mailto:xchangeapp@googlegroups.com'>xchangeapp@googlegroups.com</Link>
            </p>
            <p>Si te gusta la aplicaci&oacute;n no olvides compartirla con mas gente.
                 De igual forma puedes apoyar el proyecto mediante un donativo 💸.
                 Con ello podremos mantener vivos los servidores y mejorar la app. 🙇‍♂️</p>
            <p>Puedes hacer tu donativo al siguiente c&oacute;digo de Bitso :</p>
            <Box sx={{m: 3, marginLeft : 5}}> 
                <Link onClick={handleOpenDialog} id="bitsocode.png" style={{cursor : 'pointer'}} >Bitso</Link>
            </Box> 
            <p>O si lo prefieres a las siguientes direcciones de cryptowallets 🪙: </p>
            <ul >
                
                <li>
                    <Link onClick={handleOpenDialog} id="usdccode.png" style={{cursor : 'pointer'}}>USDC</Link>
                </li>
                <li>
                    <Link onClick={handleOpenDialog} id="ethcode.png" style={{cursor : 'pointer'}}>ETH</Link>
                </li>
                <li>
                    <Link onClick={handleOpenDialog} id="batcode.png" style={{cursor : 'pointer'}}>BAT</Link>
                </li>
                
            </ul>
        </Typography>
        <Dialog open={openDialog}>
            <DialogContent sx={{textAlign : 'center'}}>
                {openDialog && codeId !== bitsoCode && <img src={codeUrl} alt='Codigo bitso'/>}
                <Typography variant="body1" color='primary' sx={{p: 2}}>
                    Direcci&oacute;n :
                </Typography>
                <Typography variant="body1" color="initial">
                    { codeId === 'xrpcode.png' ? 'rLSn6Z3T8uCxbcd1oxwfGQN1Fdn5CyGujK' : 
                        codeId==='usdccode.png'? '0x1497042f9342122eeaf0e64bca070e6a0d76ed9d' : 
                        codeId==='batcode.png'? '0x1497042f9342122eeaf0e64bca070e6a0d76ed9d' : 
                        codeId==='ethcode.png'? '0xe5b71C14e4525AA802ddE7Cc93C62A752004c3E7' : 
                        ''
                    }
                      
                </Typography>
                <Typography variant="body1" color='primary' sx={{p: 2}}>
                    Destination tag
                </Typography>
                <Typography variant="body1" color="initial">
                    { codeId === 'xrpcode.png' ? '95492244' : 
                        codeId==='btccode'? '' : 'NO APLICA'
                    }
                      
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>{setCodeId(bitsoCode); setOpenDialog(false);}}>OK</Button>
            </DialogActions>
        </Dialog>
      
        {/* <ins className="adsbygoogle"
            style={{display: 'block'}}
            data-ad-client="ca-pub-5192058722952200"
            data-ad-slot="7403756452"
            data-ad-format="auto"
            data-full-width-responsive="true"></ins> */}
    </Box> );
}

export default Donations;