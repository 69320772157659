import { Create, LoginRounded } from "@mui/icons-material";
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Button, Typography, Link } from "@mui/material";
import { set } from "draft-js/lib/EditorState";
import React, { useEffect, useState } from 'react';
import { useUser } from "reactfire";
import { LocalStorageService } from "../../services/LocalStorageService";

function Instructions() {
    const cache = new LocalStorageService();
    const [open, setOpen] = useState(!cache.getInstructionsFlag());
    function handleClose() {
      cache.updateInstructionsFlag();
      setOpen(false);
    }


    return (
        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Como funciona XChange
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" >
            <Typography variant="body1" color="initial">XChange es una app para intercambiar articulos de segunda mano, sin usar dinero. 
            Nuestro objetivo es incentivar y facilitar el trueque entre usuarios de la app.</Typography>
            <Typography variant="body1" color="initial" sx={{m:2}}>Instrucciones</Typography>
            <Typography variant="body2" color="initial" sx={{m:1}}>
                <ol>
                    <li>Reg&iacute;strate dando click en  <LoginRounded/>. 
                      Por favor lee nuestro <Link href="/avisoprivacidad">Aviso de privacidad</Link>&nbsp;antes de registrarte. 
                      </li>
                    <li>Publica tus art&iacute;culos dando click en <Create/>. 
                    Tus art&iacute;culos quedar&aacute;n publicados durante 60 d&iacute;as y seran recomendados 
                    a vendedores con articulos de el mismo valor. </li>
                    <li>La app te recomendar&aacute;, art&iacute;culos de valor similar. Revisalos, y en caso de estar interesado 
                        env&iacute;a un mensaje al vendedor. </li>
                    <li>
                      Los mensajes que mandes a cada vendedor ser&aacute;n privados entre t&uacute; y el vendedor. 
                    </li>
                </ol>
            </Typography>
            <Typography variant="body1" color="initial" sx={{m:2}}>Recomendaciones</Typography>
            <Typography variant="body2" color="initial" sx={{m:1}}>
                <ol>
                    <li>Ingresa tu c&oacute;digo postal en las publicaciones para que te podamos hacer mejores
                      recomendaciones de productos.
                    </li>
                    <li>No publiques datos personales o direcciones particulares en la pag&iacute;na. Util&iacute;za la sección de mensajes
                      de cada post para acordar la entrega de art&iacute;culos. XChange se deslinda de cualquier interacción entre usuarios fuera de la plataforma. 
                    </li>
                    <li>
                      El obetivo de la app es el intercambio de art&iacute;culos sin dinero. Intenta ser parte de la din&aacute;mica.
                      En caso de que no tengas algun art&iacute;culo para intercambiar, y est&aacute;s interesado en otro, puedes ofrecer efectivo. 
                    </li>
                </ol>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
}

export default Instructions;