export class LocalStorageService {


    saveAllPosts(posts){
        if(posts && posts.length ){
            localStorage.setItem('posts', JSON.stringify(posts));
            localStorage.setItem('lastPostsUpdate', new Date());
        }
        
    }

    getAllPosts(){
        const lastPostsUpdate =  new Date(localStorage.getItem('lastPostsUpdate'));
        let posts = localStorage.getItem('posts');
        posts = JSON.parse(posts);

        if(!posts || !lastPostsUpdate 
            || lastPostsUpdate.getTime() < new Date() -   10 * 60 * 1000){
            posts = [];
        }
        
        return posts;
    }

    getUserPosts(userId){
        const userPosts = this.getAllPosts();
        return userPosts.filter(p => p.userid === userId);
    }

    getZipCode(){
        return localStorage.getItem('zipcode');
    }

    setZipCode(zipcode){
        localStorage.setItem('zipcode', zipcode);
    }

    #addDraftPost(post){
        let draftPosts = this.getDraftPosts();
        draftPosts.push(post);
        this.#parseAndSet('draftPosts', draftPosts);
        return draftPosts;
    }

    getDraftPosts(newPosts){
        let draftPosts = this.#getAndParse('draftPosts') || [];
        let lastDraft = draftPosts.sort( (a, b)=> b.createddate - a.createddate).find(p=> p.id) || {};
        
        const duplicated = newPosts && newPosts.length && newPosts.find(p => p.id === lastDraft.id);

        if(duplicated 
            || (lastDraft.createddate 
                && new Date(lastDraft.createddate) < new Date() - 1000 * 60 * 20)){
            localStorage.removeItem('draftPosts');
            draftPosts = [];
        }

        return draftPosts;
    }

    getPost(id){
        let post = {};
        let posts = localStorage.getItem('posts');
        posts = JSON.parse(posts);

        if(posts && posts.length){
            post = posts.find(p => p.id === id);
        }

        return post;
    }

    updatePost(post){
        let posts = localStorage.getItem('posts');
        posts = JSON.parse(posts);

        if(posts && posts.length){
            let index = posts.findIndex(p => p.id === post.id);
            posts[index] = post;
            localStorage.setItem('posts', JSON.stringify(posts));
            localStorage.setItem('lastPostsUpdate', new Date());
        }

        return post;
    }

    addPost(post){
        let posts = localStorage.getItem('posts');
        posts = JSON.parse(posts);
        if(posts && post && post.id){
            posts.push(post);
            localStorage.setItem('posts', JSON.stringify(posts));
            localStorage.setItem('lastPostsUpdate', new Date());
        }

        if(post.status === 'draft'){
            this.#addDraftPost(post);
        }

        return posts;
    }

    removePost(id){
        let posts = localStorage.getItem('posts');
        posts = JSON.parse(posts);
        if(posts && posts.length){
            let index = posts.findIndex(p => p.id === id);
            posts.splice(index);
            localStorage.setItem('posts', JSON.stringify(posts));
            localStorage.setItem('lastPostsUpdate', new Date());
        }
    }

    clearPosts(){
        localStorage.removeItem('posts');
    }

    getInstructionsFlag(){
        let accepted = localStorage.getItem('instructionsAccepted');
        return Boolean(accepted);
    }

    updateInstructionsFlag(){
        localStorage.setItem('instructionsAccepted', true);
    }

    addLoginFail(){
        let times  = this.#getAndParse('loginFails') || { times : 0 };
        times.times += 0 ;
        times.date = new Date();
        times = this.#parseAndSet('loginFails', {...times});
    }

    #parseAndSet(key, obj){
        obj = JSON.stringify(obj);
        localStorage.setItem(key, obj);
        return obj ; 
    }

    getLoginTimesFailed(){
        let times = this.#getAndParse('loginFails') ;
        if(times && times.timestamp && new Date(times.timestamp) > 1000 * 60 * 15){
            times = times.times ; 
        } else {
            times = 0;
        }
        return times ;
    }

    #getAndParse(key){
        let storedValue = localStorage.getItem(key);
        try {
            storedValue = JSON.parse(storedValue);
        } catch (error) {
            console.log(error);
        }
        return storedValue;
    }

    setGetLocationDeclined(declined){
        
        if(declined){
            localStorage.setItem('locationDeclined', 1);
        }
        return localStorage.getItem('locationDeclined');
    }

}