import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import  Login  from "../Login";
import { Balance, InstallMobile } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useState } from "react";


const Header = () => {
  const [showInstall, setShowInstall] = useState(true);
  const [installed, setInstalled] = useState(false);

  async function installApp(){
    let deferredPrompt = window.deferredPrompt;
    console.log(window.deferredPrompt);
    if (deferredPrompt !== null) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === 'accepted') {
          setInstalled(true);
          deferredPrompt = null;
      }
    }
  }

  React.useEffect(() => {
    if(window.matchMedia('(display-mode: standalone)').matches){
      setShowInstall(false);
    }
  }, [installed]);

  return (
    <AppBar position="static" sx={{ bgcolor : "primary"}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters >
          <Balance sx={{ mr: 2 }}></Balance>
          <Typography
            variant="h4"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
              width: '85%'
            }}
          >
            iXChange (beta)
          </Typography>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              color: "inherit",
              textDecoration: "none",
              width: '85%'
            }}
          >
            iXChange (0.1)
          </Typography>
          {showInstall && window.deferredPrompt &&
          <IconButton onClick={installApp} size="small" sx={{color : 'primary.light', bgcolor:"primary.dark", marginRight : 1 }}
          title='Instalar aplicación' >
            <InstallMobile  ></InstallMobile>
          </IconButton>}
          <Login></Login>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Header;
