
import {List} from '@mui/material';
import AppListItem from './AppListItem';


function AppList(props) {

  const handleSelect =  ( selectedId ) => { 
    const selectedPost = props.items.find(p =>  p.id === selectedId );
    props.onSelectedPost(selectedPost);
  }

  function handleFinished(finishedId) {
    props.onFinishedPost(finishedId);
  }

  return (
      <List  >
          {props.items.map((item) => (
                <AppListItem key={item.id}
                  title={item.title} img={item.img} content={item.content} post={item}
                  user={props.user} onSelected={handleSelect} onFinished={handleFinished}
                ></AppListItem>
              ))}
      </List>
  );
}

export default AppList;
