import {  Alert, Avatar, Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Input, InputAdornment, Link, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import {  useAuth, useUser } from "reactfire";
import { signInWithPopup, GoogleAuthProvider, FacebookAuthProvider, 
        signOut, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { useState } from "react"; 
import { Email, Key, LoginRounded } from "@mui/icons-material";
import { Box } from "@mui/system";

function Login() { 

  const auth = useAuth();
  const user =  useUser();
  let provider =  null;

  const signUp = async function (type) {
    if(type === 'Google'){
      provider = new GoogleAuthProvider();
    } else if (type === 'Facebook') {
      provider =  new FacebookAuthProvider();
    } 

     await signInWithPopup(auth, provider);
    
  };

  const callSignOut = async  (  ) => { 
    await signOut(auth);
   }

  const [anchorElUser, setAnchorElUser] = useState(null);
  const [openedMenu, setOpenedMenu] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [password2, setPassword2] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [signInOrUp, setSignInOrUp] = useState('signIn');

  const loginTypes = ["Google", "Facebook", "Email & Password"];
  const userOptions = ["Salir"];
 
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
    setOpenedMenu(event.currentTarget.dataset.buttonType);
  };

  const handleCloseUserMenu = (event) => {
    let option = event.currentTarget.dataset.value ;
    if(option === 'Facebook' || option === 'Google')
      signUp(option);
    else if(option === 'Salir'){
      callSignOut();
    } else if (option === 'Email & Password'){
      setOpenDialog(true);
    }
    setAnchorElUser(null);
    setOpenedMenu(null);
  };

  async function loginEmailPassword() {


    if(email && password ){
      try{
        if(signInOrUp === 'signUp' && password === password2)
          await createUserWithEmailAndPassword(auth, email, password);
        else if(signInOrUp === 'signIn'){
          await signInWithEmailAndPassword(auth, email, password);
        }
        setOpenDialog(false);
        setEmail(null);
        setErrorMessage(null);
        setPassword(null);
        setPassword2(null);
    } catch(e){
      console.log(e.message);
        if(e.message.includes('wrong-password')){
          setErrorMessage('Contraseña incorrecta. ');
        } else if (e.message.includes('too-many-requests')){
          setErrorMessage('Demasiados intentos. La cuenta ha sido bloquada temporalmente');
        } else
          setErrorMessage('Error inesperado, contactenos a xchangeapp@googlegroups.com');
      }
    } else {
      setErrorMessage('Ingresa un correo y contraseña validos. ');
    }
  }



  function resetPassword() {

    if(email && email.includes('@') && email.includes('.')){
      sendPasswordResetEmail(auth, email).then((r) => {
        alert('Hemos enviado un link a tu correo electrónico. ');
      });
    } else {
      setErrorMessage('Ingresa un correo valido. ');
    }
    
  }

  function closeDialog(){
    setEmail(null);
    setPassword(null);
    setErrorMessage(null);
    setOpenDialog(false);
  }

  return (
    <div>
      
      { user && user.data &&  
      <Button  onClick={handleOpenUserMenu} data-button-type="loggedIn">
        <Avatar alt={user.data.displayName} src={user.data.photoURL}></Avatar>
      </Button>}
      <Menu
          id="menu-loggedin"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={openedMenu === 'loggedIn'}
          onClose={handleCloseUserMenu}
        >
          {userOptions.map((type) => (
            <MenuItem key={type} onClick={handleCloseUserMenu} data-value={type}>
              <Typography textAlign="center" >{type}</Typography>
            </MenuItem>
          ))}
        </Menu>
      
      { !user.data &&
      <Tooltip title="Registrarme / Entrar" placement="left-end">
        <IconButton size="medium" aria-label="Registrarme" color="inherit" onClick={handleOpenUserMenu} 
        data-button-type="signIn" >
          <LoginRounded/>
        </IconButton>
      </Tooltip>
      }
      <Menu
          id="menu-signin"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={openedMenu === 'signIn'}
          onClose={handleCloseUserMenu}
        >
          {loginTypes.map((type) => (
            <MenuItem key={type} onClick={handleCloseUserMenu} data-value={type}>
              <Typography textAlign="center" >{type}</Typography>
            </MenuItem>
          ))}
        </Menu>
        <Dialog open={openDialog}>
          <DialogTitle>Email & Password</DialogTitle>
          <DialogContent>
            <ButtonGroup sx={{m : 2}}>
              <Button variant={signInOrUp === 'signIn' ? 'contained' : 'outlined'} onClick={()=>{setSignInOrUp('signIn')}}>Ingresar</Button>
              <Button variant={signInOrUp === 'signUp' ? 'contained' : 'outlined'} onClick={()=>{setSignInOrUp('signUp')}}>Registrarse</Button>
            </ButtonGroup>
            {errorMessage && <Alert severity="error" >{errorMessage}</Alert>}
            <Box sx={{m : 3}}>
              <Input placeholder="Email" fullWidth margin="dense" type="email" startAdornment={
                <InputAdornment position="start">
                  <Email></Email>
                </InputAdornment>
              } onChange={(e)=>{setEmail(e.currentTarget.value)}} sx={{m :2}} 
                error={!email || !email.includes('@') || !email.includes('.')} ></Input>
              <Input placeholder="Contraseña" fullWidth type="password" margin="dense" startAdornment={
                <InputAdornment position="start">
                  <Key></Key>
                </InputAdornment>
              } onChange={(e)=>{setPassword(e.currentTarget.value)}} sx={{m :2}} 
              error={!password || password.length < 7 }></Input>
              {signInOrUp === 'signUp' && <Input placeholder="Repite la contraseña" fullWidth type="password" margin="dense" startAdornment={
                <InputAdornment position="start">
                  <Key></Key>
                </InputAdornment>
              } onChange={(e)=>{setPassword2(e.currentTarget.value)}} sx={{m :2}} 
                error={password !== password2} required></Input>}
            </Box>
            {signInOrUp === 'signIn' && <Link  onClick={resetPassword}>Olvide mi contraseña</Link>}
          </DialogContent>
          <DialogActions>
              <Button onClick={closeDialog} variant='outlined'>Cancelar</Button>
              <Button onClick={loginEmailPassword} variant="contained">Enviar</Button>
          </DialogActions>
        </Dialog>
    </div>
  );
}

export default Login;
