import { Dialog, DialogContent, DialogActions, DialogContentText, 
Button,
Typography,
DialogTitle} from "@mui/material";
import AppList from "../list/AppList";
import { useEffect, useState } from "react";
import { useFirestore, useUser } from "reactfire";
import { where, limit, collection, query, orderBy, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

function RecommendedPosts({openDialog, post, onSelectedPost}) {
    const [recommendedPosts, setRecommendedPosts] = useState([]);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    function closeInfoDialog(params) {
        setOpen(false);
        navigate('/home');
    }

    function handleSelect(post) {
        setOpen(false);
        setRecommendedPosts([]);
        onSelectedPost(post);
    }

    const firestore = useFirestore();
    const user = useUser();

    useEffect(() =>{
        if(openDialog){
            // (async() => {
            //     let postPrice = Number(post.price) || 0;
            //     let minPrice = 0.9 * postPrice;
            //     let maxPrice = 1.1 * postPrice;

            //     let q = query(collection(firestore, "post"), 
            //                      where('price', '>=', minPrice), 
            //                      where('price', '<=', maxPrice),
            //                      where('status', '==', 'approved'), 
            //                     orderBy('price', 'asc',  'createddate', 'desc'),
            //                     limit(10));
        
            //     let data = await getDocs(q);
            //     let tempPosts = [];
            //     data.forEach((p) => {
            //         const post = p.data();
            //         if(post.userid !== user.data.uid)
            //             tempPosts.push(p.data());
            //         setRecommendedPosts(tempPosts);
            //     });
                
            // })();
         setOpen(openDialog);   
        }
    }, [openDialog]);
    return (
    <Dialog open={open} >
        <DialogTitle>Art&iacute;culo en revisi&oacute;n </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <p>Tu art&iacute;culo esta siendo revisado y ser&aacute; aprobado en breve. </p>
                {/* <p>Te recomendamos revisar los siguientes art&iacute;culos por los cuales podr&iacute;as intercambiar : </p> */}
            </DialogContentText>
            {/* <AppList items={recommendedPosts} onSelectedPost={handleSelect}></AppList> 
            {!recommendedPosts.length && 
            <Typography variant="body2" sx={{color : 'default'}}>
                Lo sentimos aun no hay art&iacute;culos con precio similar al tuyo. 
            </Typography>
            } */}
            <DialogActions >
                <Button variant="contained" onClick={closeInfoDialog} >
                    OK
                </Button>
            </DialogActions>
        </DialogContent>
    </Dialog>);
}

export default RecommendedPosts;