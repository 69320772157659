import { Outlet } from "react-router-dom";
import AppBottomNavigation from "./AppBottomNavigation";
import Header from "./header/Header";
export default function Layout() {
    return (
      <div>
        <Header></Header>
        <Outlet />
        <AppBottomNavigation />
      </div>
    );
  }