import {
    Button,
    FormControlLabel,
    Checkbox,
    TextField, Tooltip, Typography, IconButton
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useUser, useFirestore  } from "reactfire";
import { doc, updateDoc } from "firebase/firestore";
import {  Info } from "@mui/icons-material";
import { display } from "@mui/system";

export default function NewComment(props) {

    const [comment, setComment] = useState({});
    const [showCommentBox, setShowCommentBox] = useState(false);
    const [sending, setSending] = useState(false);
    const [lastCommentTime, setLastCommentTime] = useState(null);

    const user = useUser();
    const db = useFirestore();

    useEffect(() => {
        if(props.defaultMessage && props.defaultMessage.content){
            setComment(props.defaultMessage);
            setShowCommentBox(true);
        }
    }, [props.defaultMessage]);

    const handleSave = async () => {
        setSending(true);
        comment.userid = user.data.uid;
        comment.user = user.data.displayName;
        comment.createddate =  new Date();
        if(props.post){
            const comments = props.post.comments || [];
            comments.push(comment);
            const postDoc = doc(db, "post", props.post.id);
            await updateDoc(postDoc, {comments : comments});
            setLastCommentTime(new Date());
        }
        props.onCommentSent(comment);
        setShowCommentBox(false);
        setSending(false);
        
    };

    const handleCancel = function (params) {
        setComment({});
        setShowCommentBox(false);
        setSending(false);
    }
    return (
        <React.Fragment>
            { showCommentBox && <React.Fragment>
                <TextField sx={{ maxWidth: 360 }}
                autoFocus
                margin="dense"
                id="content"
                label="Enviar mensaje"
                type="text"
                fullWidth
                defaultValue={(props.defaultMessage) ? props.defaultMessage.content: ''}
                variant="outlined"
                multiline
                rows={5}
                color="secondary"
                onChange={e => setComment({...comment, content: e.target.value})}
                />
                <br />
                <Button variant="contained" onClick={handleCancel} sx={{m :2}}>
                    Cancelar
                </Button>
                <Button variant="contained" onClick={handleSave} sx={{m :2}} disabled={sending || !user || !user.data}>
                    Enviar
                </Button>
            </React.Fragment>}
            { !showCommentBox &&
                <React.Fragment>
                    {!user.data && 
                    <Typography variant="caption" sx={{display: 'block', m: 1, width : '100%'}}>
                        Inicia sesi&oacute;n para poder mandar un mensaje. 
                    </Typography>}
                    <Tooltip  
                    title="Los mensajes publicados en esta seccion son responsabilidad de los usuarios. 
                    Por favor no publiques datos personales de contacto o mensajes ofensivos. Da click en este boton si deseas reportar
                    un comentario o un post.">
                        <IconButton  href='mailto:xchangeapp@googlegroups.com'>
                            <Info sx={{m : 1}}></Info>
                        </IconButton>
                    </Tooltip>
                    <br/>
                  <Button variant="contained" onClick={() =>{setShowCommentBox(true)}} 
                    disabled={!user || !user.data || lastCommentTime > new Date() - 1000 * 60 * 5}
                    color='secondary'
                    sx={{color : '#ddd', fontWeight : 'bold'}}
                  >
                        Enviar mensaje
                    </Button>
                </React.Fragment>
                
            }
            
        </React.Fragment>
    );
}


