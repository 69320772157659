import * as React from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Create, Home } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";

export default function AppBottomNavigation(props) {
  const navigate = useNavigate();
  const path = useLocation();
  const [value, setValue] = React.useState(1);

  function go(e) {
    navigate("/" + e.currentTarget.id, {replace: true});
  }
  
  React.useEffect(() => {
        setValue(path.pathname === '/new' ? 2 : 
        path.pathname === '/donativos' ? 0 :
        1);
  }, [path.pathname]);

  return (
    <Box sx={{ width: "100%", position: "fixed", left: 0, bottom: 0 }}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          go(event);
        }}
      >
        <BottomNavigationAction
          label="Donar"
          icon={<FavoriteIcon />}
          href="/donativos"
        />
        <BottomNavigationAction label="Inicio" icon={<Home />} id="home" />
        <BottomNavigationAction label="Publicar" icon={<Create />} id="new" />
      </BottomNavigation>
    </Box>
  );
}
