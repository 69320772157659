import {
  Button,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  DialogActions,
  Typography,
  InputLabel,
  Alert,
  Input, 
  LinearProgress,
  InputAdornment,
  Checkbox,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { AddAPhotoOutlined } from "@mui/icons-material";
import { useUser, useFirestore, useStorage } from "reactfire";
import { addDoc, collection } from "firebase/firestore";
import { ref, uploadBytes } from "firebase/storage";
import Post from "../../model/post";
import RecommendedPosts from "./RecommendedPosts";
import { LocalStorageService } from "../../services/LocalStorageService";
import { useNavigate } from "react-router-dom";

export default function NewPost(props) {
  const [openInfo, setOpenInfo] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [postModel] = useState(new Post());
  const [photos, setPhotos] = useState(null);
  const [post, setPost] = useState({});
  const [inProgress, setInProgress] = useState(false);
  const [defaultZip, setDefaultZip] = useState('76269');

  const cache =  new LocalStorageService();
  const navigate = useNavigate();
  const user = useUser();


  useEffect(() => {
    setOpenModal(Boolean(user.data));
    if (user.data){
      const zip = cache.getZipCode();
      if(zip)
        setDefaultZip(zip);
    
  }
  }, [user.data, cache ]);


  const handleCancel = () => {
    navigate('/home');
  };

  
  const db = useFirestore();

  const handleSave = async () => {
    setOpenModal(false);
    setInProgress(true);
    post.userid = user.data.uid;
    post.createddate = new Date().getTime();
    post.status = "draft";
    post.zipcode = post.zipcode || defaultZip;
    const postRef = await addDoc(collection(db, "post"), post);
    post.id = postRef.id;
    cache.addPost(post);
    uploadPhotos(post.id);
    if(post.zipcode)
      cache.setZipCode(post.zipcode);
  };

  const [postIsValid, setPostIsValid] = useState(false);

  

  const checkPostIsValid = () => {
    let isValid = true;
    for (const key in postModel) {
      if (postModel[key].required) {
        isValid = isValid && post[key];
      }
    }

    setPostIsValid(isValid);
  };

  const setPostValues = (target) => {
    if (postModel[target.id] && postModel[target.id].type === 'number') {
      post[target.id] = Number(target.value);
    } else {
      post[target.id] = target.value;
    }
    checkPostIsValid();
  };

  const storage = useStorage();

  const uploadPhotos = (postId) => {
    if (photos && photos.length) {
      const photosList = Array.from(photos);

      photosList.forEach((photo, i) => {
        const postsPhotos = ref(
          storage,
          "/postphotos/" + postId + "/" + photo.name
        );
        uploadBytes(postsPhotos, photo).then((snapshot) => {
          console.log("Uploaded a blob or file!");
        }).catch(e => alert('Hubo un error al cargar las fotos'));

        if(i+1 === photos.length){
          setInProgress(false);
          setOpenInfo(true);
        }

      });
      
    }
    
  };


  const cropFirstPhoto = function (files) {
    //var canvas = document.getElementById("photocanvas");
    var display = document.getElementById("displayfirstphoto");
    //const ctx = canvas.getContext("2d");
    //ctx.clearRect(0, 0, canvas.width, canvas.height);
    const filesSizeValid = checkFilesSize(Array.from(files));
    if (files && files[0] && filesSizeValid) {
      var reader = new FileReader();
      reader.onload = function (e) {
        var img = new Image();
        img.onload = () => {
          display.src = img.src;
        };
        img.src = e.target.result;
        
      };
      reader.readAsDataURL(files[0]);
    }
  };

  function checkFilesSize(files) {
    var isValid = false;
    if(files && files.length){
        var filesSize =  0;
        filesSize = files.reduce((acc, file) => acc + file.size, filesSize);
        isValid = filesSize <= 4000000;
    }

    if(!isValid){
      alert('Las fotos que seleccionaste son mayores a 4MB. Te sugerimso recortarlas o subír menos archivos. ');
      setPhotos([]);
      
    }
    
    return isValid;
  }

  function handleSelectedPost(post) {
    setOpenInfo(false);
    props.onSelectedPost(post);
  }

  
  function giveAway(e){
    const checked = e.currentTarget.checked;
    if(checked)
      setPost({...post, price : 0});
    else{
      setPost({...post, price : null});
    }
  }

  return (
    <Box >
      
      {!user.data && 
      <Alert severity="warning">Registrate o ingresa para poder publicar.</Alert>}
      <Dialog open={openModal} fullScreen={true}>
        <DialogTitle sx={{ textAlign : 'center'}}>💻🕹👒 Nuevo art&iacute;culo 👟🎲🎻</DialogTitle>
        {inProgress && <LinearProgress/>}
        <DialogContent>
          <TextField
            autoFocus
            onChange={(event) => setPostValues(event.target)}
            margin="dense"
            id="title"
            label={postModel.title.label}
            type="text"
            fullWidth
            variant="outlined"
            color="secondary"
            required={postModel.title.required}
            inputProps={{minLength : 3, maxLength : '100'}}
          />
          <TextField
            margin="dense"
            id="content"
            label="Descripción"
            type="text"
            fullWidth
            variant="outlined"
            multiline
            rows={4}
            color="secondary"
            onChange={(event) => setPostValues(event.target)}
            required={postModel.content.required}
          />
          <TextField
            margin="dense"
            id="price"
            label="Precio"
            inputProps={{ maxLength: 5, min: 1, step: 50 
            ,  }}
            InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}
            type="text"
            variant="outlined"
            disabled={post.price === 0}
            color="secondary"
            onChange={(event) => setPostValues(event.target)}
            required={postModel.price.required}
            fullWidth
          /> 
          <FormControlLabel control={<Checkbox color="secondary"  />} 
          label="Regalar artículo" onChange={giveAway}/>
          <Typography
            variant="caption"
            color="default.light"
            sx={{ m: 1, display: "block" }}
          >
            El precio ser&aacute; utilizado para comparar contra
            art&iacute;culos similares, no ser&aacute; mostrado en la
            p&aacute;gina.
          </Typography>
           <React.Fragment>
            <TextField
                margin="dense"
                id="zipcode"
                label={postModel.zipcode.label}
                inputProps={{ maxLength: 5, min: 1, step: 50 }}
                type={postModel.zipcode.type}
                fullWidth
                variant="outlined"
                defaultValue={defaultZip}
                color="secondary"
                onChange={(event) => setPostValues(event.target)}
                required={postModel.zipcode.required}
              />
            <Typography
                variant="caption"
                color="default.light"
                sx={{ m: 1, display: "block" }}
              >
                C&oacute;digo postal donde entregaras el art&iacute;culo. No es
                requerido, pero nos ayuda a darte mejores recomendaciones.
            </Typography>
          </React.Fragment>
          
          <InputLabel sx={{ p: 1, cursor: 'pointer', borderWidth : 2, textAlign : 'center', borderRadius : 2, 
                  borderColor : 'secondary.light', borderStyle : 'solid', width : 200}}>Cargar Fotos <AddAPhotoOutlined/>
          <Input
            type="file"
            onChange={(e) => {
              setPhotos(e.target.files);
              cropFirstPhoto(e.target.files);
            }}
            
            inputProps={{accept : ".jpg, .png, .jpeg, .gif", capture: 'environment', 
                multiple: true, alt : 'Cargar', style : {display : 'none'}}}
          ></Input></InputLabel>
          <Typography
            variant="caption"
            color="default.light"
            sx={{ m: 1, display: "block" }}
          >
            La primera foto ser&aacute; utilizada como foto principal. Utiliza
            una imagen peque&ntilde;a y cuadrada.
            <p>
              As&iacute; se ver&aacute; la im&aacute;gen en la p&aacute;gina principal
              ...{" "}
            </p>
          </Typography>
          <img id="displayfirstphoto" width={140} />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCancel}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            onClick={handleSave}
            disabled={!postIsValid}
            color='secondary'
            sx={{color : '#ddd'}}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
      <RecommendedPosts
        openDialog={openInfo}
        post={post}
        onSelectedPost={handleSelectedPost}
      ></RecommendedPosts>
    </Box>
  );
}
