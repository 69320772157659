import {initializeApp} from 'firebase/app';
import {getAuth, GoogleAuthProvider, signInWithPopup} from 'firebase/auth'
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDccI6qCaHC0N0SEsJMmLbiJpYudwckVkM",
  authDomain: "xchange-de12f.firebaseapp.com",
  projectId: "xchange-de12f",
  storageBucket: "xchange-de12f.appspot.com",
  messagingSenderId: "12997242183",
  appId: "1:12997242183:web:afdc2296e3970eaffb7062",
  measurementId: "G-9TV1WJLZ1S"
};

const firebaseApp = initializeApp(firebaseConfig);

//const db = firebaseApp.firestore();
const auth =  getAuth(firebaseApp);

const gAuthProvider =  new GoogleAuthProvider();


function signInWithGoogle(){
    signInWithPopup(auth, gAuthProvider).then(result =>{
    }).catch(err => console.error(err));
}

export {  auth, signInWithGoogle, firebaseConfig}