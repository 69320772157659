import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./components/Home";
import PostDetail from "./components/post/PostDetail";
import Layout from "./components/Layout";
import Donations from "./components/info/Donations";
import NewPost from "./components/post/NewPost";
import AvisoPrivacidad from "./components/info/AvisoPrivacidad";
import ReviewPosts from "./components/post/ReviewPosts";
export default function AppRoutes() {
    return (
        <BrowserRouter>
            <Routes>
            <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="home" element={<Home />} />
                <Route path="/new" element={<NewPost />} />
                <Route path="/donativos" element={<Donations />} />
                <Route path="/avisoprivacidad" element={<AvisoPrivacidad />} />
                <Route key='/post' path='/post/:id'  element={<PostDetail/>}/>
                <Route path='/revisarposts123456789987654321'   element={<ReviewPosts/>}/>
            </Route>
            </Routes>
        </BrowserRouter>
    );
  }