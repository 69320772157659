import {
  CardGiftcardOutlined,
  CurrencyExchange,
  DateRange,
  ExpandMore,
  Launch,
  Radar,
} from "@mui/icons-material";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Box,
  Divider,
  Chip,
  IconButton,
  Popover,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {  useStorage, useUser } from "reactfire";
import { getDownloadURL, ref, listAll } from "firebase/storage";
import NewComment from "./NewComment";
import Sold from "./Sold";
import { useNavigate, useParams } from "react-router-dom";
import { LocalStorageService } from "../../services/LocalStorageService";
import AppList from "../list/AppList";

export default function PostDetail({ post, onClose }) {
  let { id } = useParams();
  const cache = new LocalStorageService();

  const [showDialog, setShowDialog] = useState(true);
  const [postPhotos, setPostPhotos] = useState([]);
  const [comments, setComments] = useState([]);
  const [innerPost, setInnerPost] = useState(cache.getPost(id));
  const [openPop, setOpenPop] = useState(false);
  const [popAnchor, setPopAnchor] = useState(null);
  const [popText, setPopText] = useState("");
  const [recommended, setRecommended] = useState(false);
  const [userPosts, setUserPosts] = useState([]);

  const storage = useStorage();
  const user = useUser();
  const navigate = useNavigate();

  function showComments(tempPost) {
    if (tempPost.comments && user.data && tempPost.comments.length) {
      let tempComments = tempPost.comments.filter(
        (c) => tempPost.userid === user.data.uid || c.userid === user.data.uid
      );
      setComments(tempComments);
    }
  }

  function closePop() {
    setPopAnchor(null);
    setOpenPop(false);
  }

  useEffect(() => {
    showComments(innerPost);
    if (
      id &&
      innerPost &&
      (!innerPost.photos ||
        (innerPost.photos.length < 2 && !innerPost.onlyOnePhoto))
    ) {
      const postFolder = ref(storage, "postphotos/" + id);

      listAll(postFolder)
        .then(async (res) => {
          async function getPhotos() {
            const photos = [];
            for (let index = 0; index < res.items.length; index++) {
              const item = res.items[index];
              const url = await getDownloadURL(item);
              photos.push(url);
            }
            return photos;
          }

          try {
            let photos = await getPhotos();
            setPostPhotos(await getPhotos());
            cache.updatePost({
              ...innerPost,
              photos: photos,
              onlyOnePhoto: photos.length === 1,
            });
          } catch (error) {
            console.log(error);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (innerPost && innerPost.photos) {
      setPostPhotos(innerPost.photos);
    }

    if (user && user.data && innerPost.userid !== user.data.uid) {
      let tempUserPosts = cache.getUserPosts(user.data.uid);
      let xchangeble = [];
      for (let i = 0; i < tempUserPosts.length; i++) {
        const p = tempUserPosts[i];
        if (
          innerPost.price >= p.price * 0.8 &&
          innerPost.price <= p.price * 1.2
        ) {
          xchangeble.push(p);
        }
      }
      setRecommended(xchangeble.length > 0);
      setUserPosts(xchangeble);
    }
  }, [showDialog, post, user.data, id]);


  function handlePostFinished(post) {
    cache.removePost(post.id);
    navigate("/");
  }

  const updateComments = function (newComment) {
    let tempPost = innerPost;
    tempPost.comments = comments;
    tempPost.comments.push(newComment);
    cache.updatePost(tempPost);
    setInnerPost(tempPost);
    showComments(tempPost);
  };

  function showPop(e) {
    e.stopPropagation();
    setPopText(e.currentTarget.dataset.text);
    setPopAnchor(e.currentTarget);
    setOpenPop(true);
  }

  const [autoXchangeMessage, setAutoXchangeMessage] = useState(null);
  function xchange(post) {
    let message =
      "Hola, ¿Te interesaría cambiar por éste artículo ? : " + post.title;
    setAutoXchangeMessage({ content: message, postid: post.id });
    setExpanded('comments');
  }

  const [expanded, setExpanded] = useState('description');

  function accordionChanged(active) {
      setExpanded(active);
  }

  return (
    <Box
      sx={{
        textAlign: "center",
        width: { xs: "90%", md: "80%" },
        margin: "auto",
        marginBottom: 10,
        position: "relative",
      }}
    >
      <Typography variant="h5" sx={{ m: 2 }} component="div">
        <span> {innerPost.title} &nbsp;</span>
        {innerPost.createddate && (
          <Chip
            size="small"
            icon={<DateRange />}
            label={new Date(innerPost.createddate).toLocaleDateString()}
          ></Chip>
        )}
        {innerPost.isNear && (
          <IconButton
            size="medium"
            onClick={showPop}
            data-text={"Códico Postal : " + innerPost.zipcode}
            color="success"
          >
            <Radar />
          </IconButton>
        )}
        {recommended && (
          <IconButton
            size="medium"
            onClick={showPop}
            data-text="Tienes  art&iacute;culos con valor similar"
            color="success"
          >
            <CurrencyExchange />
          </IconButton>
        )}
        {!innerPost.price && (
          <IconButton
            size="medium"
            onClick={showPop}
            data-text="El vendedor está regalando esté artículo"
            color="success"
          >
            <CardGiftcardOutlined />
          </IconButton>
        )}
      </Typography>

      <Divider />
      <Box sx={{ maxHeight: 400, overflow: "auto", m: 2 }}>
        {postPhotos &&
          postPhotos.map((item) => (
            <img
              key={item}
              height={300}
              style={{ margin: 3 }}
              src={`${item}?w=164&h=164&auto=format&fit=fill`}
              srcSet={`${item}?w=164&h=164&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
            />
          ))}
      </Box>

      <Sold post={innerPost} onFinishedPost={handlePostFinished}></Sold>

      <Accordion expanded={expanded === 'description'} onChange={e=>accordionChanged('description')} sx={{bgcolor : '#f2f8ff'}}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header"
        expandIcon={<ExpandMore color="secondary"/>}>
          <Typography
            variant="h6"
            sx={{ p: 2, width: "80%", textAlign: "left", margin: "auto", fontSize: 16 }}
            color="primary"
          >
            Descripción
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            variant="body1"
            color="initial"
            sx={{ width: "80%", textAlign: "left", margin: "auto" }}
          >
            {innerPost.content}
          </Typography>
          {user.data && innerPost.userid === user.data.uid && (
            <React.Fragment>
              <Typography
                variant="subtitle2"
                sx={{ p: 1, width: "100%", textAlign: "center",  }}
              >
                Precio estimado (no se mostrara a otros usuarios) : ${" "}
                {innerPost.price}
              </Typography>
            </React.Fragment>
          )}
        </AccordionDetails>
      </Accordion>

      {userPosts.length > 0 && (
        <Accordion expanded={expanded === 'userPosts'} onChange={e=>accordionChanged('userPosts')} sx={{bgcolor : '#f2f8ff'}}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header"
          expandIcon={<ExpandMore color="secondary"/>}>
            <Typography
              variant="h6"
              sx={{ p: 2, width: "80%", textAlign: "left", margin: "auto", fontSize: 16 }}
              color="primary"
            >
              Tus art&iacute;culos intercambiables
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box >
              <AppList
                items={userPosts}
                onSelectedPost={xchange}
                onFinishedPost={""}
              ></AppList>
            </Box>
          </AccordionDetails>
        </Accordion>
      )}

      <Accordion expanded={expanded === 'comments'} onChange={e => accordionChanged('comments')} sx={{bgcolor : '#f2f8ff'}}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header"
        expandIcon={<ExpandMore color="secondary"/>}>
          <Typography
            variant="h6"
            sx={{ p: 2, width: "80%", textAlign: "left", margin: "auto", fontSize: 16 }}
            color="primary"
          >
            Comentarios
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ flexGrow: 1 }}>
            <List
              sx={{
                width: "80%",
                bgcolor: "background.paper",
                position: "relative",
                margin: "auto",
              }}
            >
              {comments.map((comment, i) => (
                <React.Fragment key={i}>
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary={comment.user}
                      secondary={
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {comment.content}
                        </Typography>
                      }
                    />
                    {comment.postid && (
                      <IconButton href={"/post/" + comment.postid}>
                        <Launch></Launch>
                      </IconButton>
                    )}
                  </ListItem>
                  <Divider variant="fullWidth" component="li" />
                </React.Fragment>
              ))}
            </List>
            {!comments.length && "No hay comentarios aun"}

            <Box sx={{ m: 2 }} />
            <NewComment
              post={innerPost}
              onCommentSent={updateComments}
              defaultMessage={autoXchangeMessage}
            ></NewComment>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Popover
        id="poptext"
        open={openPop}
        anchorEl={popAnchor}
        onClose={closePop}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        sx={{ color: "success.main" }}
      >
        <Typography
          variant="body2"
          color="success"
          sx={{ p: 2, color: "success.main" }}
        >
          {popText}
        </Typography>
      </Popover>
    </Box>
  );
}
