import React, { useEffect, useState } from "react";
import { useFirestore, useUser } from "reactfire";
import { Tooltip, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import {  Delete } from "@mui/icons-material";
import { updateDoc, doc } from "firebase/firestore";

function Sold({post, onFinishedPost}) {
    const user =  useUser();
    const firestore = useFirestore();
    const [sold, setSold] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        setSold(post.status === 'finished');
    }, [post, openDialog]);

    async function updatePost(e){
        e.preventDefault();
        const postDoc = doc(firestore, "post", post.id);
        await updateDoc(postDoc, {status : 'finished'});
        setOpenDialog(false);
        onFinishedPost({...post, status : 'finished'});
    }

    function handleOpen(){
        setOpenDialog(true);
    }
    return ( <React.Fragment>
        {user.data &&  post.userid === user.data.uid && !sold &&
          <Tooltip title="Marcar como vendido">
            <IconButton color="default" onClick={handleOpen}  size='medium' id="sold">
                <Delete/>
            </IconButton> 
          </Tooltip>}
          {openDialog && 
          <Dialog open={openDialog}  aria-labelledby={'finishpost'}>
            <DialogTitle id={'finishpost'}>
               Articulo vendido
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                ¿Deseas marcar el articulo como vendido ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={updatePost}
                    color="primary"
                >
                    Si
                </Button>
                <Button
                    onClick={ e => setOpenDialog(false)}
                    color="primary">
                    No
                </Button>
            </DialogActions>
          </Dialog>}
    </React.Fragment> );
}

export default Sold;