import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  FirestoreProvider,
  useFirebaseApp,
  AuthProvider,
  StorageProvider,
} from "reactfire";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { useEffect } from "react";
import AppRoutes from "./AppRoutes";
import PostDetail from "./components/post/PostDetail";
import { getStorage } from "firebase/storage";
import { LocalStorageService } from "./services/LocalStorageService";

const theme = createTheme(
  {
    palette: {
      primary: {
        main: '#0077b0',
      },
      secondary: {
        main: '#f25500',
      }
    },
  }
);

function App() {


  const firebaseApp = useFirebaseApp();
  const fireStoreInstance = getFirestore(firebaseApp);
  const fireStorage = getStorage();
  
  const auth = getAuth();

  useEffect(() => {
    document.title = "iXChange - Zibata";
  });

  return (
    <ThemeProvider theme={theme}>
    <AuthProvider sdk={auth}>
      <FirestoreProvider sdk={fireStoreInstance}>
        <StorageProvider sdk={fireStorage}>
          <AppRoutes />
        </StorageProvider>
      </FirestoreProvider>
    </AuthProvider>
     </ThemeProvider>
  );
}

export default App;
