import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import AppList from "../list/AppList";
import {
  getDocs,
  query,
  where,
  collection,
  orderBy,
  limit,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { useFirestore, useStorage, useUser } from "reactfire";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import { listAll, ref, deleteObject } from "firebase/storage";

function ReviewPosts(props) {
  const [posts, setPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState({});
  const firestore = useFirestore();
  const user = useUser();
  const storage = useStorage();

  function postSelected(post) {
    setSelectedPost(post);
  }

  const setPostValues = (target) => {
    if (target.type === "number") {
      selectedPost[target.id] = Number(target.value);
    } else {
      selectedPost[target.id] = target.value;
    }
  };

  async function savePost() {
    const postDoc = doc(firestore, "post", selectedPost.id);
    try {
      await updateDoc(postDoc, selectedPost);
    } catch (error) {
      console.log(error);
    }
    setSelectedPost({});
  }

  async function deletePost() {
    try {
      if (selectedPost.title === "delete") {
        const postDoc = doc(firestore, "post", selectedPost.id);
        await deletePostFiles();
        await deleteDoc(postDoc);
      }
    } catch (error) {
      console.log(error);
    }
    setSelectedPost({});
  }

  async function deletePostFiles() {
    const postFolder = ref(storage, "postphotos/" + selectedPost.id+'/');

    listAll(postFolder)
      .then(async (res) => {
        for (let index = 0; index < res.items.length; index++) {
            const item = res.items[index];
            await deleteObject(item);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    
    
  }

  useEffect(() => {
    if (
      user.data &&
      user.data.email.includes("edgar.drummer")
    ) {
      async function doQuery() {
        let q = query(
          collection(firestore, "post"),
          where(
          "createddate", ">", new Date().getTime() - 1000 * 60 * 60 * 24 * 90
          ),
          orderBy("createddate", "desc"),
          limit(100)
        );

        let querySnapshot = await getDocs(q);
        let tempPosts = [];
        querySnapshot.forEach((p) => {
          let post = p.data();
          post.id = p.id;
          tempPosts.push(post);
        });
        setPosts(tempPosts);
      }
      doQuery();
    }
  }, [user.data, selectedPost.id, posts.length]);

  return (
    <Box>
      <AppList items={posts} onSelectedPost={postSelected}></AppList>
      <Dialog open={Boolean(selectedPost.id)}>
        <DialogContent>
        <TextField
            label={"ID"}
            defaultValue={selectedPost.id}
            fullWidth
            disabled={true}
            sx={{ m: 1 }}
            InputLabelProps={{ shink: true }}
          />
          <TextField
            label={"Titulo"}
            defaultValue={selectedPost.title}
            fullWidth
            id="title"
            sx={{ m: 1 }}
            InputLabelProps={{ shink: true }}
            onChange={(e) => setPostValues(e.target)}
          />
          <TextField
            label={"Descripcion"}
            defaultValue={selectedPost.content}
            fullWidth
            id="content"
            sx={{ m: 1 }}
            InputLabelProps={{ shink: true }}
            onChange={(e) => setPostValues(e.currentTarget)}
          />
          <TextField
            label={"Status"}
            defaultValue={selectedPost.status}
            fullWidth
            id="status"
            sx={{ m: 1 }}
            InputLabelProps={{ shink: true }}
            onChange={(e) => setPostValues(e.currentTarget)}
          />
          <TextField
            label={"Código Postal"}
            defaultValue={selectedPost.zipcode}
            fullWidth
            id="zipcode"
            sx={{ m: 1 }}
            InputLabelProps={{ shink: true }}
            onChange={(e) => setPostValues(e.currentTarget)}
          />
          <TextField
            label={"Fecha"}
            defaultValue={selectedPost.createddate}
            fullWidth
            id="createddate"
            type="number"
            sx={{ m: 1 }}
            InputLabelProps={{ shink: true }}
            onChange={(e) => setPostValues(e.currentTarget)}
          />
          <TextField
            label={"Precio"}
            defaultValue={selectedPost.price}
            type="number"
            fullWidth
            id="price"
            sx={{ m: 1 }}
            InputLabelProps={{ shink: true }}
            onChange={(e) => setPostValues(e.currentTarget)}
          />
          <TextField
            label={"user id"}
            defaultValue={selectedPost.userid}
            fullWidth
            id="userid"
            sx={{ m: 1 }}
            InputLabelProps={{ shink: true }}
            onChange={(e) => setPostValues(e.currentTarget)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={deletePost}>Borrar</Button>
          <Button onClick={() => setSelectedPost({})}>Cerrar</Button>
          <Button onClick={savePost}>Guardar</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ReviewPosts;
