import {
  ListItemText,
  Divider,
  Chip,
  Box,
  ListItemButton,
  Typography,
  Popover, IconButton
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useStorage, useUser } from "reactfire";
import { getDownloadURL, ref, listAll } from "firebase/storage";
import { CardGiftcardOutlined, CurrencyExchange, DateRange, GifBoxOutlined, Radar, Warning } from "@mui/icons-material";
import { LocalStorageService } from "../../services/LocalStorageService";
import Post from "../../model/post";

function AppListItem(props) {
  const cache = new LocalStorageService();
  const cachedPost = cache.getPost(props.post.id) || { photos: [""] };
  const storage = useStorage();
  const [recommended, setRecommended] = useState(false);
  const [firstPhoto, setFirstPhoto] = useState((cachedPost.photos) ? cachedPost.photos[0] : undefined);
  const [openPop, setOpenPop] = useState(false);
  const [popAnchor, setPopAnchor] = useState(null);
  const [popText, setPopText] = useState('');
  const [isNear, setIsNear] = useState();
  const user = useUser();

  const handleClick = (event) => {
    props.onSelected(props.post.id);
  };

  useEffect(() => {
    let post =  new Post(props.post);
    if (!firstPhoto) {
      const postFolder = ref(storage, "postphotos/" + props.post.id);
      listAll(postFolder)
        .then((res) => {
          if (res && res.items && res.items.length) {
            async function getPhoto() {
              const photo = res.items[0];
              let url = "";
              if (photo) {
                url = await getDownloadURL(photo);
                setFirstPhoto(url);
                cache.updatePost({...props.post, photos : [url]});
              }
            }
            getPhoto();
          }
        })
        .catch((error) => {
          console.log(error);
        });
        
        
    }

    if (user && user.data && props.post.userid !== user.data.uid) {
      let tempUserPosts = cache.getUserPosts(user.data.uid);

      for (let i = 0; i < tempUserPosts.length; i++) {
        const p = tempUserPosts[i];
        if (props.post.price >= p.price * 0.8 && props.post.price <= p.price * 1.2){
          setRecommended(true);
          break;
        }
      }

      let zip =  cache.getZipCode() ;
      setIsNear(post.isNear(zip, user.data.uid));
    }


  }, [firstPhoto, user.data]);

  function closePop() {
    setPopAnchor(null);
    setOpenPop(false);
  }

  function showPop(e){
    e.stopPropagation();
    setPopText(e.currentTarget.dataset.text);
    setPopAnchor(e.currentTarget);
    setOpenPop(true);
  }


  return (
    <div key={props.id}>
      <ListItemButton
        alignItems="flex-start"
        key={props.title}
        onClick={handleClick}
      >
        <img
          src={firstPhoto}
          loading="lazy"
          alt="Imagen no disponible"
          style={{ margin: 15, height: "auto", width: 120, minWidth : 120, maxHeight : 150 }}
        ></img>
        <ListItemText
          primary={props.title}
          secondary={
            <React.Fragment>
              <Typography sx={{ p: 1, maxHeight : 100, overflow : 'clip', textOverflow : 'ellipsis' }}>
                {props.content} 
              </Typography>
              {props.post.createddate && (
                <Chip
                  size="small"
                  icon={<DateRange />}
                  label={new Date(props.post.createddate).toLocaleDateString()}
                ></Chip>
              )}
              {props.post.status === 'draft' && (
                <Chip
                  size="small"
                  icon={<Warning />}
                  label='Art&iacute;culo en revision'
                  color='warning'
                ></Chip>
              )}
              {isNear && (
                <IconButton  size='medium' onClick={showPop} 
                  data-text='Art&iacute;culo cercano a ti.' color="success">
                    <Radar />
                </IconButton>
              )}
              {recommended && (
                <IconButton  size='medium' onClick={showPop} 
                  data-text='Tienes un art&iacute;culo con valor similar' color="success">
                    <CurrencyExchange />
                </IconButton>
              )}
              {! props.post.price && (
                <IconButton  size='medium' onClick={showPop} 
                  data-text='El vendedor está regalando esté artículo' color="success">
                    <CardGiftcardOutlined />
                </IconButton>
              )}
            </React.Fragment>
          }
        ></ListItemText>
        <Box sx={{ display: "flex" }}></Box>
      </ListItemButton>
      <Divider variant="fullWidth" component="li" sx={{ borderWidth : 1}}/>
      <Popover id='poptext' open={openPop} anchorEl={popAnchor} onClose={closePop} 
        anchorOrigin={{vertical : 'bottom', horizontal : 'center'}} sx={{color:'success.main'}}>
          <Typography variant="body2" color="success" sx={{p : 2, color : 'success.main'}}>
            {popText}
          </Typography>
      </Popover>
    </div>
  );
}

export default AppListItem;
