import { Box, Dialog, DialogTitle, Typography } from "@mui/material";

function AvisoPrivacidad(props) {
    return ( 
    <Box sx={{width : '80%', margin : 'auto', position : 'relative', paddingBottom : 10}}>
        <Typography variant="h4" color="initial" sx={{textAlign : 'center'}}>
            Aviso de Privacidad de App Xchange. 
        </Typography>
        <Typography>
            <p> App XChange es el responsable del tratamiento de los datos personales que nos proporcione. </p>
           <p style={{fontWeight : 'bold'}}>Finalidades de tratamiento</p>
           <p> Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades : </p>
           <ol>
            <li>Verificar su identidad y autenticarse en la aplicación.</li>
            <li>Enviar notificaciones vía correo electronico.</li>
            <li>Procesar y mejorar las recomendaciones de articulos mostrados en la p&aacute;gina.</li>
            <li>Procesamiento de datos para fines estadisticos</li>
           </ol>
           <p style={{fontWeight : 'bold'}}>Datos personales recabados</p>
           <p>Para las finalidades antes se&ntilde;aladas se podran solicitar los siguientes datos personales :</p>
           <ol>
            <li>Correo electr&oacute;nico</li>
            <li>Código postal</li>
            <li>ID de usuario de Google o Facebook</li>
           </ol>
           <p style={{fontWeight : 'bold'}}>Transferencia de datos personales</p>
           <p>Le informamos que sus datos personales son compartidos con las personas, empresas,
            organizaciones y autoridades distintas al responsable, y para los fines que se describen a
            continuación</p>
            <ol>
                <li>Destinatario: Google, LLC. Con domicilio en California Estados Unidos
                    <p>Los datos que usted comparta serán transferidos y almacenados en los servicios de Google, LLC. </p>
                </li>
            </ol>
            <p>Adicional a lo ya mencionado, no se realizarán transferencias de datos personales,
                 salvo aquéllas que sean necesarias para atender requerimientos debidamente fundados 
                 y motivados, provenientes de una autoridad competente. </p>
        </Typography>
    </Box> 
    );
}

export default AvisoPrivacidad;